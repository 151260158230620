/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import Modal from "./Components/Modal/Modal";

import AndreasImage from "./Assets/Images/1669127199754.jpeg";
import AlejandroImage from "./Assets/Images/1668422858775.jpeg";
import AmritImage from "./Assets/Images/1704195534256.jpeg";

import UniversalPicturesImage from "./Assets/Images/Universal-Pictures.png";
import SonyMusicImage from "./Assets/Images/Sony-Music-Spain.png";
import CashConvertersImage from "./Assets/Images/cash-converters.jpeg";
import WaynaboxImage from "./Assets/Images/waynabox.jpeg";
import PenguinImage from "./Assets/Images/penguin-books-logo.jpeg";

import "./App.css";

function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem("hasVisited", "true");
  };

  return (
    <div style={{ maxWidth: "700px", margin: "0 auto" }}>
      <header className="animate__animated animate__zoomInDown">
        <img
          src="https://assets-global.website-files.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          style={{ position: "relative" }}
        />
        <p
          style={{
            position: "absolute",
            bottom: "4px",
            fontSize: "12px",
            fontWeight: "black",
          }}
        >
          for investors
        </p>
      </header>
      <div className="container animate__animated animate__fadeInDown animate__delay-1s">
        {/* Problema */}
        <div className="section">
          <h2>Problem 🥲</h2>
          <p>
            Brands want to collaborate with small creators but cannot do so in a
            scalable way, as the process is very manual, tedious and
            time-consuming.
          </p>
        </div>

        {/* Solución */}
        <div className="section">
          <h2>Solution 😍</h2>
          <p>
            CRUWI is a platform that allows brands to collaborate with dozens
            and hundreds of small creators in an innovative way allowing them to
            make an impact with the same results as with a big influencer but
            for a fraction of the cost.
          </p>
        </div>

        {/* Misión */}
        <div className="section">
          <h2>Mission ⭐️</h2>
          <p>
            Facilitate the creation of authentic, high-impact content through an
            automated and scalable platform.
          </p>
        </div>

        {/* Visión */}
        <div className="section">
          <h2>Vision 👁️</h2>
          <p>
            To become the world's leading platform for collaboration between
            brands and small content creators, revolutionizing influencer
            marketing by making nano and micro influencer campaigns accessible,
            efficient and profitable across all social networks.
          </p>
        </div>

        {/* Descripción del negocio */}
        <div className="section">
          <h2>Business Description 🗒️</h2>
          <p>
            CRUWI is a platform that connects small content creators with brands
            looking to collaborate on TikTok and UGC videos. We handle the
            entire process, from matching creators with brands to managing the
            content creation process and payments.
          </p>
        </div>

        {/* Oportunidad */}
        <div className="section">
          <h2>Opportunity 🤑</h2>
          <p>
            The influencer marketing industry continues to grow. This year,
            2024, it is expected to grow to $24 billion with an estimated of 34%
            CAGR from 2023 to 2030. There are more and more company budgets for
            influencer marketing. 86% of marketers already have a budget
            allocated to it and 69% use TikTok for their influencer marketing
            efforts.
          </p>
        </div>

        {/* GTM */}
        <div className="section">
          <h2>Go To Market ⚔️</h2>
          <p>
            Because CRUWI's ideal client is a medium to large consumer or
            entertainment company that launches new products on a regular basis,
            our GTM strategy is:
          </p>
          <br />
          <ul>
            <li style={{ marginBottom: "10px" }}>
              1. Collaborate with marketing and influencer agencies to become a
              supplier to the nano and micro segment.
            </li>
            <li style={{ marginBottom: "10px" }}>
              2. Attendance at relevant marketing events (since budget
              decision-makers are at these events)
            </li>
            <li style={{ marginBottom: "10px" }}>
              3. Content marketing (adding value in the sector via LinkedIn +
              trendsvirales.com + blog)
            </li>
            <li style={{ marginBottom: "10px" }}>
              4. Launch targeted marketing campaigns to attract content brands
              and content creators to the platform.
            </li>
            <li style={{ marginBottom: "10px" }}>
              5. Launch CRUWI challenges and generate videos on TikTok (we have
              acquired many customers this way).
            </li>
          </ul>
        </div>

        {/* Métricas clave */}
        <div className="section">
          <h2>Key Metrics 📊</h2>
          <div className="metrics">
            <div className="metric">
              <h3>+50</h3>
              <p>Brands</p>
            </div>
            <div className="metric">
              <h3>+16.000</h3>
              <p>Creators</p>
            </div>
            <div className="metric">
              <h3>+4.500</h3>
              <p>Videos Created</p>
            </div>
            <div className="metric">
              <h3>+60k</h3>
              <p>Billed</p>
            </div>
          </div>
        </div>

        {/* Clientes */}
        <div className="section">
          <h2>Major clients 👩🏼‍💼</h2>
          <div className="clients">
            <div
              style={{
                backgroundImage: `url(${UniversalPicturesImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div
              style={{
                backgroundImage: `url(${SonyMusicImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />

            <div
              style={{
                backgroundImage: `url(${CashConvertersImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div
              style={{
                backgroundImage: `url(${WaynaboxImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div
              style={{
                backgroundImage: `url(${PenguinImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </div>
        </div>

        {/* Ronda */}
        <div className="section">
          <h2>Funding 💰</h2>
          <p>
            We are looking to raise 250k in total. 125k from private investors
            and 125k from ENISA.
          </p>
        </div>

        {/* Uso de los fondos */}
        <div className="section">
          <h2>Use of Funds 💳</h2>
          <p>
            The €250,000 investment will be used strategically in 3 key areas to
            drive CRUWI's growth and expansion. The following details how the
            funds will be allocated:
          </p>
          <br />
          <ul>
            <li style={{ marginBottom: "10px" }}>
              1. Marketing and Sales: we will hire 2 full time people to be in
              charge of marketing and sales, networking, attending events and
              GTM strategy.
            </li>
            <li style={{ marginBottom: "10px" }}>
              2. Product Development: hire our co-CTO Javier Sintes to be
              full-time.
            </li>
            <li style={{ marginBottom: "10px" }}>
              3. Reserve for Contingencies: in order to be able to continue the
              business until profitability and contingencies are met.
            </li>
          </ul>
        </div>

        {/* Por qué nosotros */}
        <div className="section">
          <h2>Why Us 🙄</h2>
          <p>
            We have shown that we know how to pivot (twice) and have a robust
            team. We have launched 4 products on the market in less than 2 years
            and in the last 10 month we have gone from 0€ to 60k, having this
            month the record with 10.000€ invoiced. Our team is balanced in 2
            people who develop, one dedicated to sales and business strategy and
            the other to product, marketing and customer communication. We
            believe we have a great team and we want to keep proving it with
            your help.
          </p>
        </div>

        {/* Fundadores */}
        <div className="section">
          <h2>Founders 👑</h2>
          <div className="founder">
            <img src={AndreasImage} alt="Founder 1" />
            <div>
              <h3>Andreas Rutjens</h3>
              <p>
                Full stack web developer - Marketing degree and Master's degree
                in Entrepreneurship - In charge of vision and strategy, investor
                relations and front-end development.
              </p>
            </div>
          </div>

          <div className="founder">
            <img src={AlejandroImage} alt="Founder 2" />
            <div>
              <h3>Alejandro Frías</h3>
              <p>
                Marketing and product - Degree in Economics and Specialist in
                Growth Marketing - In charge of product development and customer
                management and success.
              </p>
            </div>
          </div>

          <div className="founder">
            <img src={AndreasImage} alt="Founder 1" />
            <div>
              <h3>Javier Sintes</h3>
              <p>
                CTO - Full stack web developer - Degree in Computer Science -
                Responsible for platform and infrastructure development.
              </p>
            </div>
          </div>

          <div className="founder">
            <img src={AmritImage} alt="Founder 3" />
            <div>
              <h3>Amrit Baxani</h3>
              <p>
                Go to market and strategy - Degree in Marketing and Business
                Management (currently providing support, not full time)
              </p>
            </div>
          </div>
        </div>

        {/* Contacto */}
        <div className="section">
          <h2>Contact</h2>
          <p>
            If you are interested in learning more about investing in CRUWI,
            please contact us at{" "}
            <a href="mailto:info@cruwi.com">hello@cruwi.com</a>. <br /> <br />
            Andreas Rutjens, Co-Founder: +34 672767097
          </p>
        </div>

        <div>Thanks for your time :)</div>

        <footer>
          <p>© 2024 CRUWI. All Rights Reserved.</p>
        </footer>
      </div>
      <Modal show={showModal} onClose={handleCloseModal}>
        <h2 style={{ textAlign: "center" }}>👀 CAUTION 👀</h2>
        <p style={{ marginBottom: "10px" }}>
          You are about to see the data of a startup that has a great team,
          invoices several thousand per month and is close to profitability. Are
          you sure you want to continue? (if I were you, I would)
        </p>
        <button
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            border: "none",
            width: "100%",
            height: "40px",
            borderRadius: "12px",
            backgroundColor: "#77DD77",
          }}
          onClick={handleCloseModal}
        >
          Yes, I'm in
        </button>
      </Modal>
    </div>
  );
}

export default App;
